import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RPCResponse } from '@neonevm/token-transfer-core';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class HttpRpcClient {

  get rpcUrl(): string {
    if (['devnet', 'localnet'].includes(environment.network)) {
      return environment.urls.neon;
    }
    return environment.urls.everstake;
  }

  private req(method: string, params: any[] = []) {
    const id = Date.now();
    return { id, jsonrpc: '2.0', method, params };
  }

  /** Send a request to the node */
  public rpc<T>(method: string, params: any[] = [], url = this.rpcUrl): Observable<T> {
    const payload = this.req(method, params);
    // @ts-ignore
    return this.http.post<RPCResponse<T>>(url, payload).pipe(map(({ result, error }) => {
      if (error) throw error;
      return result;
    }));
  }

  constructor(private http: HttpClient) {
  }
}
