import { Injectable } from '@angular/core';
import { erc20Abi, neonWrapper2Abi, SPLToken } from '@neonevm/token-transfer-core';
import { AppKit, createAppKit } from '@reown/appkit';
import { EthersAdapter } from '@reown/appkit-adapter-ethers';
import { type AppKitNetwork } from '@reown/appkit-common';

import { BrowserProvider, TransactionReceipt, TransactionRequest, TransactionResponse } from 'ethers';
import { BehaviorSubject, delay, filter, from, map, Observable, ReplaySubject, Subject, switchMap, tap } from 'rxjs';
import { Big } from 'big.js';
import { environment } from '../../environments/environment';
import {
  DataStorage,
  EXCLUDED_WALLETS,
  metadata,
  NEON_WALLETS,
  neonBalance,
  neonEthersDevnet,
  neonEthersLocal,
  neonEthersMainnet,
  tokenBalance
} from '../../utils';
import { NotificationService } from '../../notifications';
import { Address, ConnectionState, NeonTokenBalance, NeonWallet } from '../../models';

@Injectable({ providedIn: 'root' })
export class WalletConnectService extends DataStorage<any> {
  projectId: string = environment.walletConnect.projectId;
  address$: BehaviorSubject<Address> = new BehaviorSubject<Address>(undefined!);
  balance$: BehaviorSubject<Big> = new BehaviorSubject<Big>(new Big(0));
  wallet$: BehaviorSubject<NeonWallet> = new BehaviorSubject<NeonWallet>(undefined!);
  chainId$: BehaviorSubject<number> = new BehaviorSubject<number>(environment.chainId!);
  provider: BrowserProvider | undefined;
  provider$: ReplaySubject<BrowserProvider> = new ReplaySubject<BrowserProvider>(0);
  refresh$: Subject<boolean> = new Subject<boolean>();
  connected$ = new BehaviorSubject<boolean>(false);
  isSameNetwork$ = new ReplaySubject<boolean>(0);
  chains: [AppKitNetwork, ...AppKitNetwork[]] = [neonEthersDevnet, neonEthersMainnet];
  modal: AppKit;
  private _snapshot: ConnectionState;
  private _pendingTimeout = 3e5;

  get address(): `0x${string}` {
    return this.address$.value;
  }

  get addressView$(): Observable<string> {
    return this.address$.pipe(map(d => {
      if (d?.length) {
        return `${d.slice(0, 7)}..${d.slice(-5)}`;
      }
      return '';
    }));
  }

  get chainId(): number {
    return this.chainId$.value;
  }

  get publicClient(): any {
    return this.provider?.getSigner();
  }

  setProvider = (p: any) => {
    if (p) {
      const ethersProvider = new BrowserProvider(p);
      this.provider = ethersProvider;
      this.provider$.next(ethersProvider);
    }
  };

  neonBalance(): Observable<NeonTokenBalance> {
    return from(neonBalance(this.provider));
  }

  wNeonBalance(token: SPLToken): Observable<NeonTokenBalance> {
    return from(tokenBalance(this.provider, token, neonWrapper2Abi));
  }

  tokenBalance(token: SPLToken): Observable<NeonTokenBalance> {
    return from(tokenBalance(this.provider, token, erc20Abi));
  }

  signTransaction = async (transaction: TransactionRequest): Promise<TransactionResponse> => {
    const signer = await this.provider!.getSigner();
    return signer.sendTransaction(transaction);
  };

  getTransactionReceipt = async (transaction: TransactionResponse): Promise<TransactionReceipt | null> => {
    await transaction.wait(1, this._pendingTimeout);
    return this.provider!.getTransactionReceipt(transaction.hash);
  };

  isSameNetworkEmit(): void {
    this.subs.push(this.chainId$.pipe(tap(chainId => {
      this.isSameNetwork$.next(chainId ? Number(chainId) === environment.chainId : true);
    })).subscribe());
  }

  providerNotifications(data: ConnectionState): void {
    const { provider, isConnected, chainId } = data;
    if (provider && isConnected) {
      const hasNetworkChanged = this._snapshot?.chainId && this._snapshot?.chainId !== chainId;
      this.n.success({
        title: hasNetworkChanged ? 'Network changed' : 'Neon wallet connected'
      });
    }
    if (!isConnected && this._snapshot?.isConnected !== isConnected) {
      this.n.success({ title: 'Neon wallet disconnected' });
    }
    this._snapshot = { ...data };
  }

  open(): void {
    this.modal.open();
  }

  signMessage(message: string): Observable<string> {
    return new Observable<string>(subscriber => {
      const signer = this.provider!.getSigner()
        .then(signer => signer.signMessage(message))
        .then(hex => subscriber.next(hex))
        .catch(e => subscriber.next(e))
        .finally(() => subscriber.complete());
    });
  }

  walletBalance(address: Address): Observable<Big> {
    return from(this.provider!.getBalance(address)).pipe(map((b: unknown) => {
      const balance = new Big(typeof b === 'bigint' ? b.toString() : 0);
      this.balance$.next(balance);
      return balance;
    }));
  }

  walletBalanceClean(): Observable<Big> {
    this.balance$.next(new Big(0));
    return this.balance$.pipe(delay(100));
  }

  async disconnect(): Promise<any> {
    this.modal.open();
  }

  clearConnection(): void {
    this.connected$.next(false);
    this.provider = undefined;
    this.address$.next(undefined!);
  }

  init(): void {
    const projectId = this.projectId;

    this.modal = createAppKit({
      adapters: [new EthersAdapter()],
      networks: this.chains,
      metadata,
      projectId,
      enableEIP6963: true,
      allWallets: 'HIDE',
      featuredWalletIds: NEON_WALLETS.filter(d => d.name !== 'Taho').map(w => w.id),
      includeWalletIds: NEON_WALLETS.map((w) => w.id),
      excludeWalletIds: EXCLUDED_WALLETS,
      enableWalletConnect: true,
      features: {
        email: false,
        socials: false,
        swaps: false,
        onramp: false
      }
    });

    this.modal.subscribeProviders((data: any): void => {
      let provider, isConnected, chainId;
      if (data.eip155) {
        provider = data.eip155;
        isConnected = true;
        chainId = Number(this.modal.getChainId());
        const address = this.modal.getAddress();
        this.setProvider(data.eip155);
        this.connected$.next(true);
        this.chainId$.next(chainId);
        this.address$.next(address as Address);
      } else {
        this.clearConnection();
      }
      this.providerNotifications({ provider, isConnected: !!isConnected, chainId });
    });

    this.subs.push(this.address$.pipe(switchMap(address => address?.length ?
      this.walletBalance(<Address>address) : this.walletBalanceClean())).subscribe());
    this.subs.push(this.refresh$.pipe(filter(() => !!this.address), switchMap(() => {
      return this.walletBalance(<Address>this.address);
    })).subscribe());
    this.isSameNetworkEmit();
  }

  refresh(): void {
    this.refresh$.next(true);
  }

  constructor(private n: NotificationService) {
    super();
    if (!environment.production) {
      this.chains.push(neonEthersLocal);
    }
  }
}
